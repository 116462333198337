import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { actionShape } from '@/components/propTypes.js'
import { detectProgrammingLanguageFromFilePath } from '@/components/actions/utils.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import { FileSymlink } from 'lucide-react'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal'

export default function GetFileContentsAction({ action, isLast = false }) {
  const filePath = action?.args?.path
  let detailsContent = `## PATH:\n${action?.args?.path}\n\n## COMMENT:\n${action?.args?.comment}\n\n`
  const language = detectProgrammingLanguageFromFilePath(filePath)
  //@TODO this is a bit of a hack, we should probably have a better way to handle this
  const languageBlock = '```' + language + '\n' + action?.outputs[0] + '\n```'
  detailsContent += '## FILE CONTENTS:\n' + languageBlock + '\n\n\n'

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={FileSymlink}
      isLast={isLast}
      title={action?.args?.comment ?? 'Read file'}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={filePath}
          terminalOutputs={languageBlock}
          errored={action?.outputs?.error}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

GetFileContentsAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
