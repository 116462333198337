import { useCallback, useEffect, useMemo, useState } from 'react'
import { useToast } from '@/components/ui/use-toast.js'
import useStore from '@/stores/useStore.js'
import { createProjectFirebaseFunction, getIterationDefault } from '@/services/Firebase.ts'
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components/catalyst/dialog.jsx'
import PropTypes from 'prop-types'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import { repositoryDefaultFields } from '@/const/defaults.js'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Label,
} from '@/components/catalyst/fieldset.jsx'
import { Input } from '@/components/catalyst/input.jsx'
import { Textarea } from '@/components/catalyst/textarea.jsx'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox.jsx'
import { Button } from '@/components/catalyst/button.jsx'

function useCreateProject() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new project.
   */
  const createFn = async ({
    organizationId,
    teamId,
    formData,
    usecaseFields,
    templateId,
    updatedFields,
    iterationDefault,
  }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)

    try {
      // pick integer from 1 to 10 randomly
      const randomImage = Math.floor(Math.random() * 10) + 1
      // Payload should look something like this:
      const payload = {
        organizationId,
        teamId,
        projectData: {
          name: formData.name,
          description: formData.description,
          image: `${randomImage}`,
          iterationDefaults: {
            environment: formData.environment.reduce((acc, field) => {
              if (
                updatedFields.iterationDefaults.environment &&
                updatedFields.iterationDefaults.environment[field.key]
              ) {
                acc[field.key] = updatedFields.iterationDefaults.environment[field.key]
              } else if (field.value && field.value !== '') {
                const existingValue = iterationDefault.environment[field.key]
                acc[field.key] = { ...existingValue, copyFieldAsItIs: true }
              }
              return acc
            }, {}),
            repository: formData.repository.reduce((acc, field) => {
              if (field.key === 'githubKey' && field.value && field.value !== '') {
                if (updatedFields?.iterationDefaults?.repository?.githubKey) {
                  acc[field.key] = { ...updatedFields?.iterationDefaults?.repository?.githubKey }
                } else {
                  const existingValue = iterationDefault.repository[field.key]
                  acc[field.key] = { ...existingValue, copyFieldAsItIs: true }
                }
              } else {
                acc[field.key] = field.value
              }

              return acc
            }, {}),
            usecase: usecaseFields.reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {}),
            templateId: templateId || null,
          },
        },
      }
      // const response = await createProjectFirebaseFunction(payload)
      setResponse(response)
      analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_CREATED, {
        organizationId,
        teamId,
        templateId,
        projectId: response?.projectId || 'N/A',
        projectName: formData.name,
      })
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createFn, resetCreateFn }
}

// <MUDuplicateProjectForm
//   projectToDuplicate={projectToDuplicate}
//   onClose={onClose}
//   onCompleted={onCompleted}
//   teams={teamsForOrganization}
//   templates={organizationForProject.templates}
//   iterationDefault={iterationDefault}
// />
function MDuplicateProjectForm({
  organizationId,
  projectToDuplicate,
  teams,
  iterationDefault,
  templates,
  onClose,
  onCreated = data => {},
}) {
  const { response, createFn, error: creationError, isCreating, resetCreateFn } = useCreateProject()

  const { toast } = useToast()

  const [selectedTemplateId, setSelectedTemplateId] = useState(iterationDefault.templateId || null)
  const [selectedTeamId, setSelectedTeamId] = useState(projectToDuplicate.teamId)

  const selectedTemplate = useMemo(() => {
    return templates[selectedTemplateId]
  }, [selectedTemplateId, templates])

  function getValueFromIterationDefaultField(iterationDefault, namespace, key) {
    const iterationDefaultField = iterationDefault[namespace][key]
    if (typeof iterationDefaultField === 'object' && iterationDefaultField?.value) {
      return iterationDefaultField?.value
    } else {
      return iterationDefaultField
    }
  }

  const defaultValues = useMemo(() => {
    return {
      name: projectToDuplicate.name,
      description: projectToDuplicate.description,
      repository: repositoryDefaultFields.map(field => ({
        ...field,
        value: getValueFromIterationDefaultField(iterationDefault, 'repository', field.key),
      })),
      environment: selectedTemplate?.iterationDefaultsTemplate?.environment?.fields.map(field => ({
        ...field,
        value: getValueFromIterationDefaultField(iterationDefault, 'environment', field.key),
      })),
    }
  }, [projectToDuplicate])

  const {
    register,
    control,
    handleSubmit,
    reset: resetForm,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: defaultValues,
  })

  const { fields: repositoryFields } = useFieldArray({
    control,
    name: 'repository',
  })

  const { fields: environmentFields } = useFieldArray({
    control,
    name: 'environment',
  })

  useEffect(() => {
    resetForm(defaultValues)
  }, [defaultValues, resetForm])

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post iteration creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Project created successfully! 🎉',
      })
      onCreated(response?.data || {})
      resetCreateFn()
      handleClose()
    }
    if (creationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating project 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating iteration:', creationError)
      resetCreateFn()
    }
  }, [response, creationError, toast, resetCreateFn, handleClose, onCreated])
  // try creating iteration
  const handleCreation = useCallback(
    data => {
      const updatedFields = Object.keys(dirtyFields).reduce(
        (acc, fieldKey) => {
          const dirtyValue = dirtyFields[fieldKey]
          if (Array.isArray(dirtyValue)) {
            acc.iterationDefaults[fieldKey] = {}
            dirtyValue.forEach((item, index) => {
              const updatedEnv = data[fieldKey][index]
              acc.iterationDefaults[fieldKey][updatedEnv.key] = {
                value: updatedEnv.value,
                dontEncrypt: updatedEnv.dontEncrypt,
              }
            })
          } else {
            acc[fieldKey] = data[fieldKey]
          }

          return acc
        },
        { iterationDefaults: {} }
      )

      if (!isCreating) {
        createFn({
          organizationId,
          formData: data,
          usecaseFields: selectedTemplate?.iterationDefaultsTemplate?.usecase?.fields,
          teamId: selectedTeamId,
          templateId: selectedTemplateId,
          updatedFields,
          iterationDefault,
        })
      }
    },
    [
      createFn,
      isCreating,
      organizationId,
      selectedTeamId,
      selectedTemplate?.iterationDefaultsTemplate?.usecase?.fields,
      selectedTemplateId,
    ]
  )

  // prepare form data for project to be created
  const onSubmit = useCallback(
    data => {
      handleCreation(data)
    },
    [handleCreation]
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create project</DialogTitle>
        <DialogBody>
          <div className="overflow-y-auto">
            <FieldGroup className="space-y-8 pt-8">
              <div>
                <Field>
                  <Label>Name</Label>
                  <Input
                    {...register('name', {
                      required: 'Project name is required',
                    })}
                    invalid={!!errors.name}
                  />
                </Field>
                {errors.name && <ErrorMessage className="mt-2">{errors.name.message}</ErrorMessage>}
              </div>
              <div>
                <Field>
                  <Label>Description</Label>
                  <Textarea
                    {...register('description', {
                      required: 'Description is required',
                    })}
                    rows={2}
                    invalid={!!errors.description}
                  />
                </Field>
                {errors.description && (
                  <ErrorMessage className="mt-2 pt-0">{errors.description.message}</ErrorMessage>
                )}
              </div>
            </FieldGroup>

            <FieldGroup className="mt-4 ">
              {Object.entries(templates).length > 1 && (
                <Field className="flex items-center justify-between">
                  <Label>Pick a template for the project</Label>
                  <Listbox
                    onChange={e => {
                      setSelectedTemplateId(e)
                    }}
                    value={selectedTemplateId}
                    className="max-w-96"
                  >
                    {Object.entries(templates).map(([templateId, template]) => (
                      <ListboxOption key={templateId} value={templateId}>
                        <ListboxLabel>{template.name}</ListboxLabel>
                      </ListboxOption>
                    ))}
                  </Listbox>
                </Field>
              )}
              {teams.length > 1 && (
                <Field className="flex items-center justify-between">
                  <Label>Pick a team for the project</Label>
                  <Listbox
                    onChange={e => {
                      setSelectedTeamId(e)
                    }}
                    value={selectedTeamId}
                    className="max-w-48"
                  >
                    {teams.map(team => (
                      <ListboxOption key={team.id} value={team.id}>
                        <ListboxLabel>{team.name}</ListboxLabel>
                      </ListboxOption>
                    ))}
                  </Listbox>
                </Field>
              )}
            </FieldGroup>

            <FieldGroup className="mt-24 space-y-8">
              <Description className="border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
                Repository setup
              </Description>

              {repositoryFields.map((field, index) => (
                <div className=" sm:py-4" key={index}>
                  <Field className="mt-2  sm:mt-0">
                    <Label>
                      <span>{field.name}</span>
                    </Label>
                    <Description className=" pb-2">{field.description}</Description>
                    {field.type === 'text-long' ? (
                      <Textarea
                        {...register(`repository.${index}.value`, {
                          required: field.required ? 'Field is required' : false,
                        })}
                        placeholder={field.placeholder}
                        textareaClassName={
                          field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                        }
                        invalid={!!errors.repository?.[index]?.value}
                      />
                    ) : (
                      <Input
                        {...register(`repository.${index}.value`, {
                          required: field.required ? 'Field is required' : false,
                        })}
                        placeholder={field.placeholder}
                        inputClassName={
                          field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                        }
                        invalid={!!errors.repository?.[index]?.value}
                      />
                    )}
                  </Field>
                  {errors.repository?.[index]?.value && (
                    <ErrorMessage className="mt-2 pt-0">
                      {errors.repository?.[index]?.value.message}
                    </ErrorMessage>
                  )}
                </div>
              ))}
            </FieldGroup>

            <FieldGroup className="mt-24 space-y-8">
              <Description className="border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
                Environment setup
              </Description>

              {environmentFields.map((field, index) => (
                <div className="sm:py-4" key={field.key}>
                  <Field className="mt-2  sm:mt-0">
                    <Label>{field.name}</Label>
                    <Description className="mb-4">{field.description}</Description>
                    <div className="grid w-full grid-cols-12">
                      <div className="col-span-5 h-full">
                        <div className="flex h-full flex-1 items-center justify-start font-mono">
                          {field.key}
                        </div>
                      </div>
                      <div className="col-span-7 flex h-full w-full flex-1 items-start">
                        {field.type === 'text-long' ? (
                          <Textarea
                            {...register(`environment.${index}.value`, {
                              required: field.required ? 'Field is required' : false,
                            })}
                            placeholder={field.placeholder}
                            textareaClassName={
                              field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                            }
                            invalid={!!errors.environment?.[index]?.value}
                          />
                        ) : (
                          <Input
                            {...register(`environment.${index}.value`, {
                              required: field.required ? 'Field is required' : false,
                            })}
                            placeholder={field.placeholder}
                            inputClassName={
                              field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                            }
                            className="h-full "
                            invalid={!!errors.environment?.[index]?.value}
                          />
                        )}
                      </div>
                    </div>
                  </Field>
                  {errors.environment?.[index]?.value && (
                    <ErrorMessage className="mt-2 pt-0">
                      {errors.environment?.[index]?.value.message}
                    </ErrorMessage>
                  )}
                </div>
              ))}
            </FieldGroup>
          </div>
        </DialogBody>
        <DialogActions>
          <Button disabled={isCreating} plain onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isCreating} className={isCreating ? 'bg-zinc-600' : ''} type="submit">
            {isCreating ? 'Working...' : 'Create project'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}

MDuplicateProjectForm.propTypes = {
  organizationId: PropTypes.string.isRequired,
  templates: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  iterationDefault: PropTypes.object.isRequired,
  projectToDuplicate: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func,
}

export default function MDuplicateProjectDialog2({
  organizationId,
  projectToDuplicate,
  isOpen,
  onClose,
  onCompleted = data => {},
}) {
  const [iterationDefault, setIterationDefault] = useState(null)
  const [isIterationDefaultLoading, setIsIterationDefaultLoading] = useState(true)
  const { toast } = useToast()

  const { getTeamsByOrgId, getOrgById } = useStore(state => ({
    getTeamsByOrgId: state.getTeamsByOrgId,
    getOrgById: state.getOrgById,
  }))

  const organizationForProject = useMemo(() => {
    return getOrgById(organizationId)
  }, [getOrgById, organizationId])

  const teamsForOrganization = useMemo(() => {
    return getTeamsByOrgId(organizationId)
  }, [getTeamsByOrgId, organizationId])

  useEffect(() => {
    if (projectToDuplicate?.id) {
      setIsIterationDefaultLoading(true)
      getIterationDefault({ projectId: projectToDuplicate.id })
        .then(iterationData => {
          setIterationDefault(iterationData.data)
          setIsIterationDefaultLoading(false)
        })
        .catch(err => {
          console.error('Error getting iteration', err)
          setIsIterationDefaultLoading(false)
          toast({
            variant: 'destructive',
            title: 'Error loading iteration defaults for project 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        })
    }
  }, [projectToDuplicate?.id])

  const loading = !teamsForOrganization || !organizationForProject || isIterationDefaultLoading

  return (
    <Dialog size="4xl" open={isOpen} onClose={onClose}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <MDuplicateProjectForm
          organizationId={organizationId}
          projectToDuplicate={projectToDuplicate}
          onClose={onClose}
          onCreated={onCompleted}
          teams={teamsForOrganization}
          templates={organizationForProject.templates}
          iterationDefault={iterationDefault}
        />
      )}
    </Dialog>
  )
}

MDuplicateProjectDialog2.propTypes = {
  organizationId: PropTypes.string,
  projectToDuplicate: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
}
