import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { createTemplate } from '@/services/Firebase.ts'
import { useToast } from '@/components/ui/use-toast.js'
import * as PropTypes from 'prop-types'
import TemplateForm from '@/components/molecules/MTemplateForm.jsx'
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components/catalyst/dialog.jsx'
import { Button } from '@/components/catalyst/button.jsx'

function useCreateTemplate() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new template.
   * @param {Object} organizationId - The orgnization id to which the template belongs to.
   * @param {String} payload - The contents of the template.

   * @returns {Promise<Object>} A promise that resolves when the template is created.
   */
  const createTemplateFn = async (organizationId, templateData) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const payload = {
        organizationId,
        templateData,
      }
      const response = await createTemplate(payload)
      setResponse(response)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateTemplateFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return {
    response,
    isCreating,
    error,
    createTemplateFn,
    resetCreateTemplateFn,
  }
}

export default function MAddTemplateToOrgDialog({
  isOpen,
  onClose,
  organizationId,
  onTemplateAdded = () => {},
}) {
  const {
    response,
    createTemplateFn,
    error: templateCreationError,
    isCreating,
    resetCreateTemplateFn,
  } = useCreateTemplate()
  //
  const { toast } = useToast()

  const defaultValues = useMemo(() => {
    return {
      template: {
        name: '',
        description: '',
        environmentVariables: [],
        usecase: {
          usecaseId: '',
          toolsets: '',
        },
      },
    }
  }, [])

  const methods = useForm({
    defaultValues: defaultValues,
  })
  const { handleSubmit, reset: resetForm } = methods

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post organization creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Organization created successfully! 🎉',
        description: '',
      })
      onTemplateAdded(response?.data || {})
      resetCreateTemplateFn()
      handleClose()
    }
    if (templateCreationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating template 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating organization:', templateCreationError)
      resetCreateTemplateFn()
    }
  }, [response, templateCreationError, toast, resetCreateTemplateFn, handleClose, onTemplateAdded])

  const onSubmit = useCallback(
    data => {
      if (!isCreating) {
        createTemplateFn(organizationId, {
          name: data?.template.name,
          description: data?.template.description,
          iterationDefaultsTemplate: {
            environment: { fields: data?.template.environmentVariables },
            usecase: {
              fields: [
                { key: 'usecaseId', value: data?.template?.usecase?.usecaseId },
                { key: 'toolsets', value: data?.template?.usecase?.toolsets },
              ],
            },
          },
        })
      }
    },
    [createTemplateFn, isCreating]
  )

  return (
    <Dialog size="3xl" open={isOpen} onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create new template</DialogTitle>
          <DialogBody>
            <TemplateForm />
          </DialogBody>
          <DialogActions>
            <Button disabled={isCreating} plain onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isCreating} className={isCreating ? 'bg-zinc-600' : ''} type="submit">
              {isCreating ? 'Working...' : 'Create Template'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

MAddTemplateToOrgDialog.propTypes = {
  organizationId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onTemplateAdded: PropTypes.func,
}
