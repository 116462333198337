import { useMemo } from 'react'
import { ConfigurationTemplate } from './types.js'
import { helpContentWithExamples } from './consts.js'

export function useHelpContent(
  activeStep: string,
  selectedField: string | null,
  selectedConfigurationTemplate: ConfigurationTemplate
) {
  return useMemo(() => {
    if (activeStep === 'summary') {
      return null
    }
    if (!selectedField) {
      return helpContentWithExamples['default']
    } else if (helpContentWithExamples[selectedField]) {
      return helpContentWithExamples[selectedField]
    } else {
      const fields = selectedConfigurationTemplate?.iterationDefaultsTemplate.environment.fields
      const foundMatching = fields?.find(f => f.key === selectedField)
      if (foundMatching) {
        return { text: foundMatching.description }
      }
    }
    return helpContentWithExamples['default']
  }, [selectedField, selectedConfigurationTemplate, activeStep])
}
