import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { FileJson2 } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'

export default function PreciselyEditFileAction({ action, isLast = false }) {
  const filePath = action?.args?.path
  let detailsContent = `## PATH:\n${filePath}\n\n## COMMENT:\n${action?.args?.comment}\n\n## EDIT INSTRUCTIONS:\n${action?.args?.edit_instructions}`

  if (action.outputs) {
    detailsContent += '## OUTPUTS:\n'
    action?.outputs?.forEach(output => {
      // check if output is an object
      if (output && typeof output === 'object') {
        const outputEntries = Object.entries(output).map(([key, value]) => `${key}: ${value}`)
        outputEntries.forEach(entry => {
          detailsContent += `${entry}`
        })
      }
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={FileJson2}
      isLast={isLast}
      title={action?.args?.comment}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={action?.args?.path}
          terminalOutputs={action?.args?.edit_instructions}
          errored={action?.outputs?.error}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

PreciselyEditFileAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
