import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Toaster } from '@/components/ui/toaster'
import * as Sentry from '@sentry/react'
import PostHogProvider from './components/providers/PostHogProvider.jsx'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import CONFIG from '@/config/config'

if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
  Sentry.init({
    dsn: CONFIG?.sentry?.dsn || '',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app\.proofs\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
  },
])
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <PostHogProvider>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
      <Toaster duration={2000} />
    </PostHogProvider>
  </React.StrictMode>
)
