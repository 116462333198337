import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { FilePen } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent'

export default function CreateCompanyBriefAction({ action, isLast = false }) {
  let detailsContent = 'No documentation provided'
  if (action.outputs && action.outputs.length > 0) {
    detailsContent = action.outputs[0]?.company_brief
  }
  return (
    <GenericAction
      action={action}
      actionIcon={FilePen}
      isLast={isLast}
      title={action.args?.comment || 'Create company brief'}
    >
      <AActionContentGenericLayout>
        <MarkdownContent detailsContent={detailsContent} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

CreateCompanyBriefAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
