import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Timestamp } from 'firebase/firestore'

import { getIteration } from '@/services/Firebase.ts'

import { useToast } from '@/components/ui/use-toast.js'
import { CheckCheck } from 'lucide-react'
import colors from 'tailwindcss/colors.js'

import OiterationDetailsSkeleton from '@/components/organisms/project-details/OiterationDetailsSkeleton.jsx'
// import MNewIterationDialog from '@/components/molecules/iteration-details/MNewIterationDialog'
import MStartStopIterationCard from '@/components/molecules/iteration-details/MStartStopIterationCard.jsx'
import MIterationStatusChangeAwaiting from '@/components/molecules/iteration-details/MIterationStatusChangeAwaiting.jsx'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics.js'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const.js'
import MElementsList from '@/components/molecules/iteration-details/MElementList.jsx'
import { calculateDuration, formatIterationDate } from '@/lib/date-utils.js'
import Logo from '@/assets/svg-components/Logo.jsx'
import { formatIndex } from '@/lib/params-utils.js'

import MContinuationPrompt2 from '@/components/organisms/project-details/OIterationDetails/MContinuationPrompt2.tsx'
import MContinuationPrompt from '@/components/organisms/project-details/OIterationDetails/MContinuationPrompt.tsx'
import { UserInteractionForm } from '@/components/organisms/project-details/OIterationDetails/UserInteractionForm.tsx'

const SHOW_ALL_DETAILS_BY_DEFAULT = false

/**
 * A component for displaying details of an iteration.
 * @param {Object} props - The props for the component.
 * @param {Object} props.iterationMeta - The iteration to display.
 * @param {Boolean} props.isNewestIteration - Whether the iteration is the newest one.
 * @param {Number} props.lastTick - The last tick time.
 * @param {Function} props.onIterationCreated - The function to call when a new iteration is created.
 * @returns {JSX.Element} The JSX element for the component.
 */
export default function OIterationDetails({
  iterationMeta,
  isNewestIteration = false,
  lastTick = Date.now(),
  onIterationCreated,
}) {
  const [isNewIterationDialogOpen, setIsNewIterationDialogOpen] = useState(false)
  const [lastIterationMeta, setLastIterationMeta] = useState(iterationMeta)
  const [iteration, setIteration] = useState({})
  const [isIterationLoading, setIsIterationLoading] = useState(true)
  const [iterationLoadingError, setIterationLoadingError] = useState(null)
  const [filterOutLLMActions, setFilterOutLLMActions] = useState(true)

  const { toast } = useToast()

  const [isAwaingCommand, isWorkingOnStatusChange] = useMemo(() => {
    const isAwaiting = iterationMeta?.awaitingCommand ? true : false
    const isDuringTransition = iterationMeta?.awaitingCommand?.active ? true : false
    return [isAwaiting, isDuringTransition]
  }, [iterationMeta])

  const allowContinuationPrompt = useMemo(() => {
    return (
      (!isAwaingCommand && !isWorkingOnStatusChange && iteration.status === 'DONE') ||
      iterationMeta?.awaitingCommand?.command === ITERATION_COMMANDS.EXTEND
    )
  }, [isAwaingCommand, isWorkingOnStatusChange, iteration.status])

  useEffect(() => {
    if (!_.isEqual(iterationMeta, lastIterationMeta)) {
      setLastIterationMeta(iterationMeta)
    }
  }, [iterationMeta, lastIterationMeta])

  useEffect(() => {
    if (iterationMeta?.id !== lastIterationMeta?.id) {
      // only set loading if the iteration id has changed to limit flickering on loading
      setIsIterationLoading(true)
    }
  }, [iterationMeta, lastIterationMeta])

  useEffect(() => {
    if (lastIterationMeta) {
      getIteration({ iterationId: lastIterationMeta.id })
        .then(iterationData => {
          setIteration(iterationData.data)
          setIsIterationLoading(false)
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN, {
            iterationId: lastIterationMeta?.id,
            projectId: lastIterationMeta?.projectId || 'N/A',
            teamId: lastIterationMeta?.teamId || 'N/A',
            organizationId: lastIterationMeta?.organizationId || 'N/A',
            iterationStatus: lastIterationMeta.status,
            vmStatus: lastIterationMeta.vmStatus || 'N/A',
          })
        })
        .catch(err => {
          console.error('Error getting iteration', err)
          setIterationLoadingError(err)
          setIsIterationLoading(false)
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN_ERROR, {
            iterationId: lastIterationMeta?.id,
            projectId: lastIterationMeta?.projectId || 'N/A',
            teamId: lastIterationMeta?.teamId || 'N/A',
            organizationId: lastIterationMeta?.organizationId || 'N/A',
            iterationStatus: lastIterationMeta.status,
            vmStatus: lastIterationMeta.vmStatus || 'N/A',
            error: err.message,
          })
          toast({
            variant: 'destructive',
            title: 'Error loading iteration details 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        })
    }
  }, [lastIterationMeta, toast])

  const elements = useMemo(() => {
    const elements = Object.entries(iteration?.elements || {}).map(([key, value]) => {
      return { id: key, ...value }
    })
    // also order elements by index
    return elements.sort((a, b) => a.index - b.index)
  }, [iteration])

  const elementsWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (lastTick && elements) {
      return elements?.map(element => {
        const dateCreated = new Timestamp(element?.createdAt)?.toDate() || Date.now()
        const dateUpdated = new Timestamp(element?.updatedAt)?.toDate() || Date.now()
        return {
          ...element,
          updatedAt: dateUpdated,
          createdAt: dateCreated,
          updatedAtRelative: DateTime.fromJSDate(dateUpdated).toRelative(),
          repoURI: iteration?.repoURI,
        }
      })
    }
  }, [elements, iteration?.repoURI, lastTick])

  const isDone = useMemo(() => {
    return iteration.status === 'DONE'
  }, [iteration])

  const canCreateNewIteration = useMemo(() => {
    return isDone
  }, [isDone])

  const groupedElements = useMemo(() => {
    const result = []
    if (iteration && elementsWithRelativeTime) {
      if (iteration?.continuationPrompts?.length > 0) {
        let lastContinuationPromptIndex = 0
        for (const continuationPrompt of iteration.continuationPrompts) {
          const afterElementIndex = continuationPrompt?.afterElementIndex
          // all elements with index less than the continuation prompt afterElementIndex should be grouped
          const elementsToGroup = elementsWithRelativeTime.filter(
            element =>
              element.index > lastContinuationPromptIndex && element.index <= afterElementIndex
          )
          result.push({
            elements: elementsToGroup,
            prompt: continuationPrompt?.prompt,
            createdBy: continuationPrompt?.createdBy,
          })
          lastContinuationPromptIndex = afterElementIndex
        }
      } else {
        // push all elements to first group
        result.push({ elements: elementsWithRelativeTime })
      }
    }

    return result
  }, [iteration, elementsWithRelativeTime])

  // if iteration status is not done, scroll the page to the bottom on iteration updates
  // also do this only if the user is scrolled to the bottom
  // Adding scrollContainer.current as a dependency

  if (isIterationLoading) {
    return <OiterationDetailsSkeleton />
  }

  return (
    <div className="flex flex-col gap-12">
      <IterationInfo iteration={iteration} />
      <div className="">
        {allowContinuationPrompt && (
          <MContinuationPrompt
            className="mb-12"
            iterationId={iterationMeta?.id}
            iterationUsecaseId={iteration?.usecaseId}
            command={iterationMeta?.awaitingCommand?.command}
          />
        )}
        {iteration.awaitingInputCommand?.command === ITERATION_COMMANDS.INPUT_REQUEST &&
          iteration.awaitingInputCommand?.active && (
            <UserInteractionForm
              initialData={iteration.awaitingInputCommand}
              iterationId={iterationMeta?.id}
            />
          )}
        {groupedElements.map((group, index) => {
          return (
            <div key={index} className="mb-12">
              <MElementsList
                elements={group.elements}
                expandAllDetails={SHOW_ALL_DETAILS_BY_DEFAULT}
                filterOutLLMActions={filterOutLLMActions}
              />
              {group?.prompt && (
                <div className="flex flex-1 justify-end p-8 pr-0">
                  <div className="flex max-w-prose flex-col items-start rounded-2xl rounded-tr-none bg-zinc-800 px-4 py-4 text-zinc-50">
                    <div className="whitespace-pre-line text-base">{group.prompt}</div>
                    <div className="mt-4 text-sm font-semibold">{group.createdBy}</div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>

      <div className="mb-24"></div>

      {!isAwaingCommand && <MStartStopIterationCard iterationMeta={iterationMeta} />}
      {isAwaingCommand &&
        [ITERATION_COMMANDS.PAUSE, ITERATION_COMMANDS.RESUME].includes(
          iterationMeta?.awaitingCommand?.command
        ) && (
          <MIterationStatusChangeAwaiting
            iterationId={iterationMeta?.id}
            isOpen={isAwaingCommand}
            awaitingCommandMeta={iterationMeta?.awaitingCommand || {}}
          />
        )}
    </div>
  )
}

OIterationDetails.propTypes = {
  iterationMeta: PropTypes.shape({
    id: PropTypes.string,
    iterationId: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    inputTokens: PropTypes.number,
    outputTokens: PropTypes.number,
    elapsedTime: PropTypes.number,
    prompt: PropTypes.string,
    createdAt: PropTypes.instanceOf(Timestamp),
    updatedAt: PropTypes.instanceOf(Timestamp),
    elements: PropTypes.object,
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    environment: PropTypes.object,
    repoURI: PropTypes.string,
    awaitingCommand: PropTypes.object,
    teamId: PropTypes.string,
  }),
  lastTick: PropTypes.number,
  onIterationCreate: PropTypes.func,
  isIterationCreating: PropTypes.bool,
  isNewestIteration: PropTypes.bool,
  onIterationCreated: PropTypes.func,
}

function IterationInfo({ iteration }) {
  if (iteration.status === ITERATION_STATUSES.DONE) {
    return (
      <div className="inline-flex h-20 items-center justify-between rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-8">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex h-6 w-6 flex-col items-center justify-center p-0.5">
            <div className="relative flex h-5 w-5 flex-col items-start justify-start">
              <CheckCheck className="text-green-500" />
            </div>
          </div>
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
            {`Iteration done Today at ${formatIterationDate(iteration?.updatedAt, 'unknown')} without any issues`}
          </div>
        </div>
        <div className="text-right font-['Inter'] text-xs font-normal leading-none text-stone-500">
          {calculateDuration(iteration?.createdAt, iteration?.updatedAt)}
        </div>
      </div>
    )
  } else if (iteration.status === ITERATION_STATUSES.RUNNING) {
    const { element, step } = findElementAndStepByStatus(iteration, ITERATION_STATUSES.RUNNING)
    // if (!element || !step) {
    //   console.log('Could not find element or step with status RUNNING')
    //   console.log('iteration with issues: ', iteration)
    // }
    return (
      <div className="inline-flex h-20 items-center justify-between rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-8">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex h-6 w-6 shrink grow basis-0 flex-col items-center justify-center self-stretch p-0.5">
            <Logo key="element-running-logo" color={colors.violet[500]} />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              {step?.name ?? element?.name ?? 'Iteration is running...'}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2.5 rounded-md border border-stone-200 px-2 py-1">
          <div className="font-['Inter'] text-xs font-medium leading-none text-stone-500">
            {`Stage ${formatIndex(element?.index)} / ${formatIndex(Object.keys(iteration?.elements ?? {}).length)}`}
          </div>
        </div>
      </div>
    )
  } else if (iteration.status === ITERATION_STATUSES.RUN_REQUESTED) {
    return (
      <div className="inline-flex h-20 items-center justify-start rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-8">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex h-6 w-6 flex-col items-center justify-center p-0.5">
            <Logo key="element-running-logo" color={colors.stone[500]} />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Starting virtual machine to work on iteration
            </div>
          </div>
        </div>
      </div>
    )
  } else if (iteration.status === ITERATION_STATUSES.PAUSED) {
    const { element } = findElementAndStepByStatus(iteration, ITERATION_STATUSES.RUNNING)
    return (
      <div className="inline-flex h-20 items-center justify-between rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-8">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex h-6 w-6 shrink grow basis-0 flex-col items-center justify-center self-stretch p-0.5">
            <Logo key="element-running-logo" color={colors.stone[500]} />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Iteration paused
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2.5 rounded-md border border-stone-200 px-2 py-1">
          <div className="font-['Inter'] text-xs font-medium leading-none text-stone-500">
            {`Stage ${formatIndex(element?.index ?? Object.keys(iteration?.elements ?? {}).length)} / ${formatIndex(Object.keys(iteration?.elements ?? {}).length)}`}
          </div>
        </div>
      </div>
    )
  }
}

function findElementAndStepByStatus(iteration, status) {
  // Find the first element with the specified status
  const matchingElement = Object.values(iteration.elements).find(
    element => element.status === status
  )

  if (!matchingElement) {
    return { element: null, step: null }
  }

  // Find the first step in the matching element with the specified status
  const matchingStep = Object.values(matchingElement.steps).find(step => step.status === status)

  return { element: matchingElement, step: matchingStep }
}
