import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'

import OCodeEditor from '@/components/organisms/OCodeEditor'
import { Button } from '@/components/catalyst/button'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/16/solid'

import useStore from '@/stores/useStore'
import { isSuperadmin as isSuperAdminFn } from '@/lib/acl-utils'

/**
 * Action Details Inspector
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {string} props.title
 * @param {string} props.content
 * @param {function} props.setIsOpen
 * @param {string | object} props.code
 * @param {string} props.language - 'markdown' | 'json'
 * @param {object} props.actionObject - JSON object of action to display with full glory
 * @returns {JSX.Element}
 */
export default function MActionDetailsInspector({
  isOpen = true,
  title,
  content,
  setIsOpen = () => {},
  code = null,
  language = 'markdown',
  actionObject = null,
}) {
  const [showActionObject, setShowActionObject] = useState(false)
  const userRoles = useStore(state => state.userRoles)

  const isSuperAdmin = useMemo(() => {
    return isSuperAdminFn(userRoles)
  }, [userRoles])
  /** @type string */
  const actionObjectStr = useMemo(() => {
    if (!actionObject || typeof actionObject !== 'object') {
      return null
    }
    return JSON.stringify(actionObject, null, 2)
  }, [actionObject])

  return (
    <>
      <Dialog open={isOpen} onClose={setIsOpen} size="5xl">
        <DialogTitle>{title}</DialogTitle>
        <div className="mt-6 w-full rounded-md bg-zinc-50 p-4 text-zinc-700">{content}</div>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          <OCodeEditor code={code} language={language} editable={false} className="w-full" />
          {isSuperAdmin && (
            <div className="mt-6 flex items-center justify-start ">
              <Button plain onClick={() => setShowActionObject(!showActionObject)}>
                {showActionObject ? <ChevronUpIcon /> : <ChevronDownIcon />}
                {showActionObject ? 'Hide raw details' : 'Show raw details'}
              </Button>
            </div>
          )}
          {isSuperAdmin && showActionObject && !!actionObjectStr && (
            <div className="mt-12 rounded-md border-b-4 border-t-4 bg-amber-400 p-4">
              <div className="mb-4 rounded-t-md font-mono text-lg font-semibold">
                Full Action Object
                <div className="mt-2 text-sm font-normal">Super-admin inspector</div>
              </div>
              <OCodeEditor
                code={actionObjectStr}
                language={'json'}
                editable={false}
                className="w-full"
              />
            </div>
          )}
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

MActionDetailsInspector.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  language: PropTypes.string,
  title: PropTypes.string,

  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  actionObject: PropTypes.object,
}
