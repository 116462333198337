import { stackTemplate, technologiesOptions } from '@/const/stackTemplates'
import {
  IterationFormData,
  iterationFormData,
} from '@/components/molecules/iteration-details/CreateIteration/types'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { Container, FolderOpen } from 'lucide-react'
import { SiGithub } from '@icons-pack/react-simple-icons'
import { helpContentWithExamples } from '@/components/molecules/project-details/CreateProject/consts.js'

export const FormStep = ({
  onSubmit,
  initialValues,
  onSeePrompt,
  submittingInProgress,
}: {
  onSubmit: (data: IterationFormData, isValid: boolean) => void
  initialValues: Partial<IterationFormData>
  onSeePrompt: () => void
  submittingInProgress: boolean
}) => {
  const inputRef = useRef(null)
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors, isValid: formIsValid },
  } = useForm<IterationFormData>({
    resolver: zodResolver(iterationFormData),
    defaultValues: initialValues,

    mode: 'onChange',
  })

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 200)
    }
  }, [])

  const [showTechnicalReq, setShowTechnicalReq] = useState(!!initialValues.technicalRequirements)
  const [showProspectReq, setShowProspectReq] = useState(!!initialValues.prospectRequirements)
  const [selectedField, setSelectedField] = useState<string | null>(null)
  const selectedTemplate = watch('projectTemplate')
  const content = selectedField
    ? helpContentWithExamples[selectedField]
    : helpContentWithExamples['prospectName']

  // const handleTemplateClick = (templateKey: string) => {
  //   if (selectedTemplate === templateKey) {
  //     setValue('projectTemplate', null, { shouldValidate: true })
  //     setValue('technologies', [], { shouldValidate: true })
  //     setValue('whatToBuild', '', { shouldValidate: true })
  //     setValue('technicalRequirements', '', { shouldValidate: true })
  //     setValue('prospectRequirements', '', { shouldValidate: true })
  //     setShowTechnicalReq(false)
  //     setShowProspectReq(false)
  //   } else {
  //     setValue('projectTemplate', templateKey, { shouldValidate: true })
  //     const template = projectTemplates.find(t => t.key === templateKey)
  //     if (template) {
  //       // ts-ignore
  //       setValue('technologies', template.technologies as IterationFormData['technologies'], {
  //         shouldValidate: true,
  //       })
  //       setValue('whatToBuild', template.whatToBuild, { shouldValidate: true })
  //       setValue('technicalRequirements', template.technicalRequirements, { shouldValidate: true })
  //       setValue('prospectRequirements', template.prospectRequirements, { shouldValidate: true })
  //       setShowTechnicalReq(true)
  //       setShowProspectReq(true)
  //     }
  //   }
  // }

  const handleSeePromptClick = () => {
    onSubmit(getValues(), formIsValid)
    onSeePrompt()
  }

  const handleExampleClick = (field: string, value: string) => {
    setValue(field as keyof IterationFormData, value, { shouldValidate: true })
  }

  const handleFormSubmit = (data: IterationFormData) => {
    onSubmit(data, formIsValid)
  }

  // Merge the refs
  const mergeRefs = useCallback(
    (el: HTMLInputElement | null) => {
      // This handles the ref from react-hook-form
      const { ref } = register('prospectName')
      if (typeof ref === 'function') {
        ref(el)
      }
      // This handles our custom ref
      inputRef.current = el
    },
    [register]
  )

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="inline-flex w-full flex-col items-end justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
        <div className="inline-flex w-full items-center justify-start gap-4 ">
          <div className="inline-flex h-[436px] w-64 flex-col items-start justify-between self-stretch pb-14">
            <div className="flex h-40 flex-col items-start justify-start gap-4 self-stretch">
              <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
                Build new PoC
              </div>
              <div className="flex h-[120px] flex-col items-start justify-center self-stretch rounded-md">
                <div className="inline-flex items-center justify-start gap-3 self-stretch rounded-lg bg-stone-200 px-4 py-2.5 ">
                  <div className="relative flex h-4 w-4 items-center justify-center">
                    <FolderOpen className="text-orange-500" />
                  </div>
                  <div className="shrink grow basis-0 font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                    Project
                  </div>
                </div>
                <div className="inline-flex cursor-not-allowed items-center justify-start gap-3 self-stretch rounded-lg px-4 py-2.5">
                  <div className="relative flex h-4 w-4 items-center justify-center">
                    <SiGithub className="relative h-4 w-4 text-stone-400" />
                  </div>
                  <div className="shrink grow basis-0 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
                    Respository
                  </div>
                </div>
                <div className="inline-flex cursor-not-allowed items-center justify-start gap-3 self-stretch rounded-lg px-4 py-2.5">
                  <div className="relative flex h-4 w-4 items-center justify-center">
                    <Container className="relative h-4 w-4 text-stone-400" />
                  </div>
                  <div className="shrink grow basis-0 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
                    Environment
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch font-['Inter'] text-sm font-normal leading-tight text-stone-500">
              {content.text}
            </div>
          </div>
          <div className="inline-flex w-full flex-col items-start justify-start gap-4 self-stretch">
            <div className="flex h-[436px] flex-col items-start justify-start gap-6 self-stretch rounded-lg border border-stone-200 bg-white p-8 shadow">
              <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
                PoC details
              </div>
              <div className="flex h-[62px] w-full flex-col gap-6 self-stretch">
                <div className=" ">
                  <Label htmlFor="prospectName" className="text-sm font-medium text-gray-700">
                    Prospect
                  </Label>
                  <Controller
                    name="prospectName"
                    control={control}
                    rules={{ required: 'Prospect Name is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          id="prospectName"
                          {...field}
                          className="my-custom-focus mt-1"
                          onFocus={() => setSelectedField('prospectName')}
                          ref={mergeRefs}
                          placeholder="ACME, Inc."
                        />
                        {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                      </>
                    )}
                  />
                </div>
                <div className="">
                  <Label htmlFor="prospectWebsite" className="text-sm font-medium text-gray-700">
                    Prospect Website
                  </Label>
                  <Input
                    id="prospectWebsite"
                    {...register('prospectWebsite')}
                    className="my-custom-focus mt-1"
                    onFocus={() => setSelectedField('prospectWebsite')}
                    placeholder="https://www.acme.com/"
                  />
                  {errors.prospectWebsite && (
                    <p className="mt-1 text-sm text-red-500">{errors.prospectWebsite.message}</p>
                  )}
                </div>
                <Controller
                  name="techStack"
                  control={control}
                  onFocus={() => setSelectedField('techStack')}
                  render={({ field, fieldState: { error } }) => (
                    <div className="w-full">
                      <Label htmlFor="technologies" className="text-sm font-medium text-gray-700">
                        Tech stack
                      </Label>
                      <Select
                        onFocus={() => setSelectedField('techStack')}
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
                      >
                        <SelectTrigger
                          onFocus={() => setSelectedField('techStack')}
                          // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                          tabIndex={0}
                          className="my-custom-focus mt-1"
                        >
                          <SelectValue placeholder="Select a stack..." />
                        </SelectTrigger>
                        <SelectContent
                          className="w-full"
                          onFocus={() => setSelectedField('techStack')}
                        >
                          {technologiesOptions.map(option => {
                            const stack = option.value
                            const TechnologyIcon = stackTemplate[stack]?.icon
                            return (
                              <SelectItem key={option.value} value={option.value}>
                                <div className="flex items-center justify-start gap-3">
                                  <div className="flex h-4 w-4 items-center justify-center">
                                    {TechnologyIcon && <TechnologyIcon />}
                                  </div>
                                  <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                                    {option.label}
                                  </div>
                                </div>
                              </SelectItem>
                            )
                          })}
                        </SelectContent>
                      </Select>

                      {error && <p className="text-red-500">{error.message}</p>}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inline-flex items-start justify-between self-stretch">
          <div className="flex h-10 w-10 items-center justify-center gap-2 rounded-md bg-stone-100 px-4 py-2">
            <div className="relative h-4 w-4" />
          </div>
          <div className="flex w-24 items-center justify-center rounded-md">
            <Button
              type="submit"
              disabled={!formIsValid || submittingInProgress}
              className="w-full bg-black  text-white"
            >
              {submittingInProgress ? 'Building...' : 'Build'}{' '}
              {/*<ArrowRight className="ml-2 h-4 w-4" />*/}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
