import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { actionShape } from '@/components/propTypes.js'
import { detectProgrammingLanguageFromFilePath } from '@/components/actions/utils.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import { FilePlus2 } from 'lucide-react'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'

export default function CreateFileAction({ action, isLast = false }) {
  const filePath = action?.args?.path
  let detailsContent = `## PATH:\n${filePath}\n\n## COMMENT:\n${action?.args?.comment}\n\n`

  const language = detectProgrammingLanguageFromFilePath(filePath)
  const languageBlock = '```' + language + '\n' + action?.args?.content + '\n```'
  detailsContent += '## FILE CONTENTS:\n' + languageBlock + '\n\n\n'
  detailsContent += '## OUTPUTS:\n'
  action?.outputs?.forEach((output, index) => {
    // if output is object
    let cleanOutput = output
    if (typeof output === 'object') {
      cleanOutput = `${output?.message} (Success?: ${output?.success}, error: ${output?.error}) \n`
    }
    detailsContent += `${index + 1}: ${cleanOutput}\n`
  })

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={FilePlus2}
      isLast={isLast}
      title={action?.args?.comment}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={action?.args?.path}
          terminalOutputs={languageBlock}
          errored={action?.outputs?.error}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

CreateFileAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
