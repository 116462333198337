'use client'

import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Eye, EyeOff } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { createIterationCommandFirebaseFunction } from '@/services/Firebase.ts'
import { ITERATION_COMMANDS } from '@/const/const'

// Define the schema for a single request
const requestSchema = z.object({
  request: z.string(),
  type: z.enum(['text', 'secret']),
  user_value: z.string(),
})

// Define the schema for the entire form
const formSchema = z.object({
  id: z.string(),
  command: z.literal('INPUT_REQUEST'),
  args: z.object({
    requests: z.array(requestSchema),
  }),
  active: z.boolean(),
})

type FormData = z.infer<typeof formSchema>

export function UserInteractionForm({
  initialData,
  iterationId,
}: {
  initialData: FormData
  iterationId: string
}) {
  const [showPasswords, setShowPasswords] = useState<{ [key: number]: boolean }>({})
  const [isWorking, setIsWorking] = useState(false)
  const [shouldHide, setShouldHide] = useState(false)
  const { toast } = useToast()

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData,
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      setIsWorking(true)
      try {
        const payload = {
          ...data,
          commandArgs: { ...data.args, request_id: initialData.id },
          command: ITERATION_COMMANDS.INPUT_ANSWER,
          iterationId,
        }

        await createIterationCommandFirebaseFunction(payload)
        // analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
        //   iterationId: iterationId,
        //   prompt: prompt,
        // })
        setShouldHide(true)
      } catch (error) {
        console.error('Error creating an answer', error)
        toast({
          variant: 'destructive',
          title: 'Error creating an answer 😔',
          description: 'Try refreshing the page or contact Proofs team.',
        })
      } finally {
        setIsWorking(false)
      }
    },
    [toast, initialData]
  )

  const togglePasswordVisibility = (index: number) => {
    setShowPasswords(prev => ({ ...prev, [index]: !prev[index] }))
  }

  if (shouldHide) {
    return null
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mb-4 space-y-8">
        {initialData.args.requests.map((request, index) => (
          <FormField
            key={index}
            control={form.control}
            name={`args.requests.${index}.user_value`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{request.request}</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input
                      {...field}
                      type={
                        request.type === 'secret' && !showPasswords[index] ? 'password' : 'text'
                      }
                      placeholder={request.user_value}
                    />
                    {request.type === 'secret' && (
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        className="absolute right-2 top-1/2 -translate-y-1/2"
                        onClick={() => togglePasswordVisibility(index)}
                      >
                        {showPasswords[index] ? (
                          <EyeOff className="h-4 w-4" />
                        ) : (
                          <Eye className="h-4 w-4" />
                        )}
                        <span className="sr-only">
                          {showPasswords[index] ? 'Hide password' : 'Show password'}
                        </span>
                      </Button>
                    )}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ))}
        <Button type="submit" disabled={isWorking}>
          {isWorking ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </Form>
  )
}
