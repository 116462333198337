import React, { Component, ReactNode } from 'react'

// Interface for Error Boundary state
interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

// Interface for Error Boundary props
interface ErrorBoundaryProps {
  children: ReactNode
  fallback?: ReactNode
  action: any
}

export class ActionErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error,
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback
      }

      return (
        <div className="rounded-md border border-red-200 bg-red-50 p-4">
          <p className="text-sm text-red-600">{`Something went wrong rendering the action ${this.props.action?.name}`}</p>
        </div>
      )
    }

    return this.props.children
  }
}
