import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { FileStack } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'

export default function ListFilesAction({ action, isLast = false }) {
  let detailsContent = `## OUTPUT:\n`
  action?.outputs?.[0]?.files.forEach((file, index) => {
    detailsContent += `${index + 1}: ${file}\n`
  })

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={FileStack}
      isLast={isLast}
      title={action?.args?.comment ?? 'List files'}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={'$ ' + action?.args?.path}
          terminalOutputs={detailsContent}
          errored={action?.outputs?.error}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ListFilesAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
